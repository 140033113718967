<template>
<div>
  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card>
        <h2 class="card-title d-flex">{{$t('PRODUCTADMIN.HEADER.TITEL')}}
          <div class="ml-auto text-right">
            <vs-button  @click="LoadData()" size="small" color="primary" type="filled" icon="refresh"></vs-button>
          </div>
        </h2>
        <p class="card-subtitle">
          <span>{{$t('PRODUCTADMIN.HEADER.TEXT')}}</span>
        </p>
      </vs-card>
    </vs-col>
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card v-if="editMode" >
        <div id="editProductCard" class="vs-con-loading__container">
          <h3 v-if="!newProduct">{{headerEditProduct}}</h3>
          <h3 v-if="newProduct">{{$t('PRODUCTADMIN.NEW.HEADER')}}</h3>
          <div class="default-input d-flex align-items-center">
            <vs-input :label="$t('PRODUCTADMIN.EDIT.LABEL.BEZ')"  class="inputx" :placeholder="$t('PRODUCTADMIN.EDIT.LABEL.BEZ')"  v-model="selected.bez" />
            <vs-input :label="$t('PRODUCTADMIN.EDIT.LABEL.KBEZ')"  class="inputx ml-3" :placeholder="$t('PRODUCTADMIN.EDIT.LABEL.KBEZ')"  v-model="selected.kbez" />
            <vs-select :label="$t('PRODUCTADMIN.EDIT.LABEL.STUECKLISTE')" class="ml-3 " v-model="selected.stueckliste_fk">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in stuecklisten" />
            </vs-select>
            <vs-select :disabled="selected.id > 0" :label="$t('PRODUCTADMIN.EDIT.LABEL.NRKREIS')" class="ml-3 " v-model="selected.numbergroup_fk">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in numbergroups" />
            </vs-select>
            <vs-select :label="$t('PRODUCTADMIN.EDIT.LABEL.PRODGROUP')" class="ml-3 " v-model="selected.productgroup_fk">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in productgroups" />
            </vs-select>           
          </div>
          <div class="default-input d-flex align-items-center mt-2">
            <vs-select :label="$t('PRODUCTADMIN.EDIT.LABEL.JOOMLA_TAG_V')" class="ml-3 " v-model="selected.joomla_tag_v_fk">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in joomlaTagsForSelect" />
            </vs-select>
            <vs-select :label="$t('PRODUCTADMIN.EDIT.LABEL.JOOMLA_TAG_H')" class="ml-3 " v-model="selected.joomla_tag_h_fk">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in joomlaTagsForSelect" />
            </vs-select> 
          </div>
          <div class="default-input d-flex align-items-center mt-2">
            <vs-input-number v-model="selected.length" :label="$t('PRODUCTADMIN.EDIT.LABEL.LENGTH')" class="inputx ml-3" />
            <vs-input-number v-model="selected.width" :label="$t('PRODUCTADMIN.EDIT.LABEL.WIDTH')" class="inputx" />
            <vs-input-number v-model="selected.height" :label="$t('PRODUCTADMIN.EDIT.LABEL.HEIGHT')" class="inputx ml-3" />
            <vs-input-number v-model="selected.weight" :label="$t('PRODUCTADMIN.EDIT.LABEL.WEIGHT')" class="inputx ml-3" />
            <vs-input-number v-model="selected.power" :label="$t('PRODUCTADMIN.EDIT.LABEL.POWER')" class="inputx ml-3" />
          </div>
          <div class="default-input d-flex align-items-center mt-2">
            <vs-checkbox v-model="selected.bestellbar" class="inputx" >{{$t('PRODUCTADMIN.EDIT.LABEL.BESTELLBAR')}}</vs-checkbox>
          </div>
          <SaveCancel class="mt-3" :SaveAllowed = saveAllowed v-on:SaveClicked="Save" v-on:CancelClicked="Cancel"></SaveCancel>   
        </div>
      </vs-card>
      <vs-card >
            <div class="d-flex align-items-center">
              <vs-button class="mt-3 mb-2"  type="filled" icon="add" @click="AddProduct">{{$t('PRODUCTADMIN.ADD')}}</vs-button>
            </div> 
            <div id="productadmin_table" class="vs-con-loading__container" >
              <vs-table
                search
                :data="products"
                :noDataText="$t('PRODUCTADMIN.NO_DATA')"
                stripe
                max-items="25" 
                pagination
                hoverFlat>

                <template slot="header">
                  <h3>
                    {{$t('PRODUCTADMIN.TABLE.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">                               
                  <vs-th sort-key="bez">
                    {{$t('PRODUCTADMIN.TABLE.COL1')}}
                  </vs-th>
                  <vs-th sort-key="kbez">
                    {{$t('PRODUCTADMIN.TABLE.COL2')}}
                  </vs-th> 
                  <vs-th sort-key="article_nr">
                    {{$t('PRODUCTADMIN.TABLE.COL8')}}
                  </vs-th> 
                  <vs-th sort-key="productgroup_fk">
                    {{$t('PRODUCTADMIN.TABLE.COL9')}}
                  </vs-th>                 
                  <vs-th sort-key="bestellbar">
                    {{$t('PRODUCTADMIN.TABLE.COL3')}}
                  </vs-th>
                  <vs-th>
                    {{$t('PRODUCTADMIN.TABLE.COL4')}}
                  </vs-th>        
                  <vs-th>
                    {{$t('PRODUCTADMIN.TABLE.COL5')}}
                  </vs-th>  
                  <vs-th sort-key="weight">
                    {{$t('PRODUCTADMIN.TABLE.COL6')}}
                  </vs-th> 
                  <vs-th sort-key="power">
                    {{$t('PRODUCTADMIN.TABLE.COL7')}}
                  </vs-th>                                        
                  <vs-th></vs-th>                                                                                                                                             
                </template>

                <template slot-scope="{data}">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data" >                    
                    <vs-td :data="tr.bez">
                      {{tr.bez}}
                    </vs-td>      
                    <vs-td :data="tr.kbez">
                      {{tr.kbez}}
                    </vs-td>   
                    <vs-td :data="tr.article_nr">
                      {{tr.article_nr}}
                    </vs-td>    
                    <vs-td :data="tr.productgroup_fk">
                      {{GetNameOfProductgroup(tr.productgroup_fk)}}
                    </vs-td>                                    
                    <vs-td :data="tr.bestellbar">
                      <vs-checkbox v-model="tr.bestellbar" disabled="true" ></vs-checkbox>
                    </vs-td>
                    <vs-td :data="tr.stueckliste_fk">
                      {{tr.stueckliste_fk}}
                    </vs-td>
                    <vs-td :data="tr.length">
                      <span>{{tr.length}}x{{tr.width}}x{{tr.height}}</span>
                    </vs-td>                   
                    <vs-td :data="tr.weight">
                      {{tr.weight}}
                    </vs-td>
                    <vs-td :data="tr.power">
                      {{tr.power}}
                    </vs-td>                    
                    <vs-td>
                      <vs-button  @click="EditProduct(tr.id)" class="ml-2" size="small" color="primary" type="filled" icon="edit"></vs-button>  
                      <vs-button  @click="OpenConfirmDeleteProduct(tr.id,tr.bez)" class="ml-2" size="small" color="primary" type="filled" icon="delete"></vs-button>                        
                    </vs-td>                    
                                                                                                                                                                                              
                  </vs-tr>
                </template>
              </vs-table>
            </div>           
        </vs-card>
      </vs-col>
  </vs-row>

</div>
</template>

<script>

import SaveCancel from './components/SaveCancel';
import helperMethods from '../helper/staticFuncHelper';
import codeHelper from '../helper/staticCodeHelper';
import {numbergroup_Types} from '../helper/enumHelper';

export default {
  name: "ProductAdmin",
  components: {
    SaveCancel
  },
    data: function (){
      return {
      products:[],
      selected:[],
      selectedOld:"",
      editMode:false,
      newProduct:false,
      defaultNrGrpFk:-1,
      numbergroups:[],
      productgroups:[]
      };
  },
  mounted () {
      this.LoadData();
      this.LoadNumberGroups();
      
      this.$store.dispatch('productgroup/getProductgroups');
      this.$store.dispatch('motif/getJoomlaTags');
    },
  computed: 
  { 
    getTableData() {
      var data = [];
      if(this.$store.state.products.productAdminList.data != null)
      {
        data = this.$store.state.products.productAdminList.data;
      }
      return data;
    },
    loadingTableData () {
      var loading = false;
      if(typeof(this.$store.state.products.productAdminList.status) != 'undefined')
        loading = this.$store.state.products.productAdminList.status.loading;
      return loading;
    },
    productDataChanged(){
        return JSON.stringify(this.selected) != this.selectedOld;
    },
    saveAllowed(){
      return this.CheckInput() && !this.productDataChanged;
    },
    stuecklisten(){
      return null;
    },
    numbergroupsForSelect(){
      return this.GetListOfNumbergroupsForSelect(numbergroup_Types.ARTIKEL);
    },
    productgroupsForSelect(){
      return this.GetListOfProductgroupsForSelect();
    },
    joomlaTagsForSelect(){
      var retVal = [];

      if(this.$store.state.motif.joomlatags.data != null)
      {
        var joomlatags = this.$store.state.motif.joomlatags.data;
        
        joomlatags.forEach(tag => {
          var title = tag.title.toLowerCase();
          if(title.startsWith("konvex") == true)
          {
            retVal.push({"text": tag.title, "value":tag.id});
          }
        });
      }
      return retVal;
    },
    headerEditProduct(){
      var txt = this.$t('PRODUCTADMIN.EDIT.HEADER');

      if(this.IsNotNull(this.selected))
      {
        txt += ' (' + this.selected.article_nr + ')';
      }

      return txt;
    }
  },
  watch: {
      getTableData(value) {
          this.products = value;
      },
      loadingTableData(value)
      {
        if(value)
        {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#productadmin_table'});
        }
        else
        {
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#productadmin_table'});
        }
      },
      numbergroupsForSelect(value)
      {
         this.numbergroups = value.numbergroups;
         this.defaultNrGrpFk = value.default;
      },
      productgroupsForSelect(value)
      {
        this.productgroups = value;
      }
    },
  methods: {    
      ...helperMethods,
      ...codeHelper,
      LoadData: function ()
      {
          this.$store.dispatch('products/getProductAdminList'); 
      },
      EditProduct(id){
        this.newBankAccount = false;
        if(this.$store.state.products.productAdminList.data != null)
        {
          for(var i = 0; i < this.$store.state.products.productAdminList.data.length; i++)
          {
            if(this.$store.state.products.productAdminList.data[i].id==id)
            {
              this.selectedOld = JSON.stringify(this.$store.state.products.productAdminList.data[i]);
              this.selected = JSON.parse(this.selectedOld);
              this.editMode = true;
              break;
            }
          }
        }
      },
      LoadNumberGroups(){
        this.$store.dispatch('numbergroup/getNumbergroupsByCompany', 0);// Companyid des aktuellen users im backend ermitteln
      },
      CheckInput(){
        var retVal = true;
        // Wenn man in das numeric dings händisch eintippt kommt es als text an... umwandeln!
        if(typeof(this.selected.length) != 'undefined')
        {
          this.selected.length = Number(this.selected.length);
        }

        if(typeof(this.selected.width) != 'undefined')
        {
          this.selected.width = Number(this.selected.width);
        }

        if(typeof(this.selected.height) != 'undefined')
        {
          this.selected.height = Number(this.selected.height);
        }
        if(typeof(this.selected.weight) != 'undefined')
        {
          this.selected.weight = Number(this.selected.weight);
        }
        if(typeof(this.selected.power) != 'undefined')
        {
          this.selected.power = Number(this.selected.power);
        }        
        return retVal;
      },
      AddProduct(){
        this.selected={id:0,company_fk:-1,bez:"",kbez:"",bestellbar:false,stueckliste_fk:-1,width:0,height:0,length:0,power:0,weight:0,numbergroup_fk:-1,joomla_tag_h_fk:-1,joomla_tag_v_fk:-1};
        this.selectedOld = JSON.stringify(this.selected);

        if(this.defaultNrGrpFk != -1)
        {
          this.selected.numbergroup_fk = this.defaultNrGrpFk;
        }

        this.editMode = true;
        this.newProduct = true;
      },
      Save(){
        this.$store.dispatch('alertqueue/showLoader', {'id':'#editProductCard'});

        var name = this.selected.bez;
            this.$store.dispatch('products/saveProduct', this.selected)
              .then(response => {
                if(response.success == true)
                {
                  this.LoadData();
                  this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('PRODUCTADMIN.SUCCESS.SAVE').replace("*1*",name)});
                  this.editMode = false;
                }
                else
                {
                    var text = this.$t('PRODUCTADMIN.ERROR.SAVE').replace("*1*",name);
                    text = text.replace("*2*",this.$t(response.text));
                    this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
                }
                this.$store.dispatch('alertqueue/hideLoader', {'id':'#editProductCard'});
              }, 
              error =>{
                this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PRODUCTADMIN.ERROR.SAVE').replace("*1*",name)+' '+error});
                this.$store.dispatch('alertqueue/hideLoader', {'id':'#editProductCard'});
              });        
      },
      Cancel(){
        this.$data.selected = [];
        this.editMode = false;
        this.newProduct = false;
      },
      OpenConfirmDeleteProduct(id,name) {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.DELETE'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('PRODUCTADMIN.QUESTION.DELETE').replace("*1*",name),
          accept: this.SendDeleteRequest,
          parameters:{"id": id,"name": name}
        });
      
      },
      SendDeleteRequest: function(parameters)
      {
        this.$store.dispatch('products/deleteProduct', parameters.id)
        .then(response => {
          if(response.success === true)
          {
            this.LoadData();
              this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('PRODUCTADMIN.SUCCESS.DELETE').replace("*1*",parameters.name)});  
          }
          else
          {
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':this.$t('PRODUCTADMIN.WARNING.DELETE').replace("*1*",parameters.name)}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PRODUCTADMIN.ERROR.DELETE').replace("*1*",error)});  
        });
      }
  }
};

</script>